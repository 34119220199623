<template>
  <div>
      <carausel></carausel>
      <Medservice></Medservice>
      <Clinic></Clinic>
      <Doctor></Doctor>
      <Information></Information>
      <news></news>
      <Contact></Contact>
      <Footer></Footer>
  </div>
</template>
  
   <script setup>
import { ref, onMounted } from "vue";
import carausel from "../components/section/carausel.vue";
import Medservice from "../components/section/medservice.vue";
import Clinic from "../components/section/clinic.vue"
import Doctor from "../components/section/doctor.vue"
import Information from '../components/section/information.vue'
import news from "../components/section/news.vue"
import Contact from "../components/section/contact.vue"
import Footer from "../components/section/footer.vue"
onMounted(() => {});
</script>