<template>
  <div>
    <Navbar></Navbar>
     <div>
        <navbarBottom></navbarBottom>
     </div>
  </div>
</template>

 <script setup>
import { ref, onMounted } from "vue";
import Navbar from "../navbar/navbar-top.vue";
import navbarBottom from "../navbar/navbar-bottom.vue"
onMounted(() => {
  // Your mounted logic here
});
</script>