<template>
  <div class="news">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <div class="news_block">
          <div class="news_title">Янгиликлар ва мақолалар</div>
          <div class="news_text">
            Марказимизда олиб борилаётган энг сўнги ишлар ҳақида батафсил
            танишинг.
          </div>
        </div>
        <div>
          <div>
            <button type="button" class="btn_gray">Барча янгиликлар</button>
          </div>
        </div>
      </div>

      <div class="news_block1">

        <div class="w-100">
          <div class="news_block1_img">
            <img src="../../assets/images/card/clinic2.png" alt="" />
          </div>
          <div class="news_block1_title">Марказ янгиликлари</div>
          <div class="news_block1_text">2024-04-27 16:13:13</div>
        </div>

        <div class="w-100">
          <div class="news_block1_img">
            <img src="../../assets/images/card/clinic2.png" alt="" />
          </div>
          <div class="news_block1_title">Марказ янгиликлари</div>
          <div class="news_block1_text">2024-04-27 16:13:13</div>
        </div>

        <div class="w-100">
          <div class="news_block1_img">
            <img src="../../assets/images/card/clinic2.png" alt="" />
          </div>
          <div class="news_block1_title">Марказ янгиликлари</div>
          <div class="news_block1_text">2024-04-27 16:13:13</div>
        </div>

      </div>
    </div>
    <hr style="margin-top: 90px;">
  </div>
</template>
<style scoped>
.btn_gray {
  background: #f5f5f7;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 16px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000;
  /* transition: 0.3s all; */
  width: 290px;
  margin-left: 32px;
}

.btn_gray:hover,
.btn_gray:focus {
  background: #ffffff;
  border: 2px solid blue;
}
</style>