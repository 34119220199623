import { createRouter, createWebHistory } from 'vue-router'
import header from './all_routers/header';
import AppHeader from "../components/layout/AppHeader.vue";
import Components from "../components/index.vue";
import AppFooter from "../components/layout/AppFooter.vue";
const routes = [
  ...header,

  {
    path: "/",
    name: "header",
    components: {
      header: AppHeader,
      default: Components,
      footer: AppFooter,

    }
  },
]

const router = createRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
