<template>
  <div>
    <div
      class="navbar__bottom"
      :class="{ 'navbar-main': scrolled, 'scroll-up': !scrolled }"
    >
      <ul class="navbar__bottom_block container">
        <li class="navbar__bottom_dropdown">
          <ul class="navbar__bottom_a">
            <a href="">Марказ ҳақида</a>
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.46997 13.26L12 9.74001L15.53 13.26"
                  stroke="#2E58FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </ul>
        </li>

        <li class="navbar__bottom_dropdown">
          <ul class="navbar__bottom_a">
            <a href="">Бўлимлар</a>
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.46997 13.26L12 9.74001L15.53 13.26"
                  stroke="#2E58FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </ul>
        </li>

        <li class="navbar__bottom_dropdown">
          <ul class="navbar__bottom_a">
            <a href="">Фан ва таълим</a>
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.46997 13.26L12 9.74001L15.53 13.26"
                  stroke="#2E58FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </ul>
        </li>

        <li class="navbar__bottom_dropdown">
          <ul class="navbar__bottom_a">
            <a href="">Интирактив</a>
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.46997 13.26L12 9.74001L15.53 13.26"
                  stroke="#2E58FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </ul>
        </li>

        <li class="navbar__bottom_dropdown">
          <ul class="navbar__bottom_a">
            <a href="">Матбуот</a>
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.46997 13.26L12 9.74001L15.53 13.26"
                  stroke="#2E58FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrolled: false, 
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll); 
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll); 
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 150) {
        this.scrolled = true; 
      } else {
        this.scrolled = false;
      }
    },
  },
};
</script>
<style scoped>
.navbar__bottom {
  display: block;
  border-top: 2px solid #f2f2f8;
  padding-top: 14px;
  padding-bottom: 16px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #fff;
  border-bottom: 1px solid #f5f5f7;
}

.navbar-main {
  position: fixed;
  width: 100%;
  top: 0;
  transition: margin-top 0.5s; /* O'zgartirishga animatsiya qo'yish uchun */
}

.scroll {
  margin-top: 0; /* Yuqoriga chiqib ketsa */
}
</style>