<template>
  <div>
    <div class="container">
      <div class="d-flex justify-content-between align-items-center medservice">
        <div class="medservice_title">Хизматлар</div>
        <div class="medservice_text">Юқори малакали тиббий хизматлар</div>
      </div>
      <div class="row justify-content-between" style="grid-gap: 40px">
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>

        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
        <div class="medservice_card">
          <div>
            <img width="100" src="../../assets/images/card/eye.png" alt="" />
          </div>
          <div>
            <h5>Катарактани даволаш</h5>
            <div class="med_card_hidden">
              <h5><a href="">Батафсил</a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
     <script setup>
import { ref, onMounted } from "vue";
onMounted(() => {});
</script>
<style scoped>
</style>