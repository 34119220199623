
<template>
  <div class="navbar">
    <div class="container">
      <div
        class="d-flex align-items-center justify-content-between w-100 pt-4 pb-4"
      >
        <div class="d-flex align-items-center">
          <div>
            <img
              width="80"
              src="../../assets/images/logo/Uzbekistan.png"
              alt="logo"
            />
          </div>
          <div class="navbar-logo-title">
            O'zbekiston Respublikasi <br />Ko'z ilmiy texnologiya markazi
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex">
            <a href="">
              <img
                width="50"
                style="margin-right: 20px"
                src="../../assets/images/card/telephone.png"
                alt=""
              />
            </a>
            <div>
              <div style="color: #c5c5ca; font-weight: 500">Kecha kunduz</div>
              <div>
                <a
                  style="
                    color: #000;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                  "
                  href="tel:"
                  >+998 (97) 860-18-18</a
                >
              </div>
            </div>

            <a href="">
              <img
                width="50"
                style="margin-left: 20px"
                src="../../assets/images/card/eye (1).png"
                alt=""
              />
            </a>
          </div>

          <div class="mar_gap">
            <button type="button" class="btn">Онлайн қабул</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

onMounted(() => {});
</script>

<style scoped>
.btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  padding: 20px 24px;
  background: #ff705d;
  box-shadow: 0px 8px 20px rgba(255, 112, 93, 0.4);
  border-radius: 12px;
  transition: 0.3s all;
  white-space: nowrap;
}

.mar_gap {
  margin: 0 25px;
}
</style>