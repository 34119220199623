import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import { createYmaps } from 'vue-yandex-maps';
import 'bootstrap/dist/css/bootstrap.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'
import "./assets/navbar/navbar.scss"
import "./assets/navbar/medservice.scss"
import "./assets/navbar/information.scss"
import "./assets/navbar/news.scss"
import "./assets/navbar/doctor.scss"
import "./assets/navbar/footer.scss"
import "./assets/header.scss"
import "./assets/variables.scss"

 axios.defaults.baseURL = 'http://eye-bukhara.uz';
 if (window.location.href.indexOf('http://eye-bukhara.uz/') > -1) {
    axios.defaults.baseURL = 'http://eye-bukhara.uz/';
 }

createApp(App).use(store).use(router).use(bootstrap)
    .use(createYmaps({
        apikey: 'your-api-key',
    })).mount('#app')
