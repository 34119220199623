<template>
  <div>
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="news_title">Контактлар</div>
        <div class="d-flex">
          <div class="best__left-item-contact">
            <img
              width="30"
              src="../../assets/images/card/location.png"
              alt=""
            />
          </div>
          <div class="news_text">
            Тошкент ш., Учтепа тумани, Кичик халка йули 14
          </div>
        </div>

        <div class="contact_phone">
          <a href="tel:">+998 (97) 860-18-88</a>
        </div>
      </div>

      <div class="contact_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1737.6221882978507!2d-98.48650795000005!3d29.421653200000023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c58aa57e6a56f%3A0xf08a9ad66f03e879!2sHenry+B.+Gonzalez+Convention+Center!5e0!3m2!1sen!2sus!4v1393884854786"
            width="100%"
            height="450"
            frameborder="0"
            style="border: 0"
          
          ></iframe>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
<style scoped>
.best__left-item-contact {
  width: 64px;
  height: 64px;
  background: #f5f5f7;
  border-radius: 50%;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>