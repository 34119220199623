<template>
  <div class="doctor">
    <div class="container">
      <div class="doctor_title">
        Марказий Осиёдаги энг малакали ва тажрибали шифокорлар
      </div>
      <div class="doctor_text">Кадрлар-бизнинг салоҳиятимиз</div>
      <div class="d-flex justify-content-between" style="grid-gap: 40px">
        <div style="position: absolute; ">
          <img
            width="500"
            height="700"
            src="../../assets//images/card/doctor_img.png"
            alt="img"
          />
        </div>
        <div class="doctor_left">
          <div class="doctor_left_card">
            <div class="doctor_left_title">Марказ директори:</div>
            <div class="doctor_left_text">Қувватов Ҳусен Набижон ўғли</div>
            <div class="text-right d-flex justify-content-between">
              <div></div>
              <img
                width="70px"
                src="../../assets/images/card/signature.png"
                alt=""
              />
            </div>
          </div>

          <div class="doctor_left_card1">
            <img src="../../assets/images/card/clinic1.jpg" alt="" />
          </div>
        </div>

        <div class="doctor_right">
          <div class="mb-5">
            <div class="progress_top">
              <div class="progress_title">Фан докторлари</div>
              <div class="progress_count">10</div>
            </div>
            <div class="progress doctor_progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 45%"
                aria-valuenow="45"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="mb-5">
            <div class="progress_top">
              <div class="progress_title">Фан номзодлари</div>
              <div class="progress_count">13</div>
            </div>
            <div class="progress doctor_progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 55%"
                aria-valuenow="55"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="mb-5">
            <div class="progress_top">
              <div class="progress_title">Олий тоифали шифокорлар</div>
              <div class="progress_count">30</div>
            </div>
            <div class="progress doctor_progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 70%"
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="mb-5">
            <div class="progress_top">
              <div class="progress_title">Муваффақиятли операциялар</div>
              <div class="progress_count">89%</div>
            </div>
            <div class="progress doctor_progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 89%"
                aria-valuenow="89"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="mb-5">
            <div class="progress_top">
              <div class="progress_title">Илғор технологиялар ва ускуналар</div>
              <div class="progress_count">100%</div>
            </div>
            <div class="progress doctor_progress">
              <div
                class="progress-bar"
                role="progressbar"
                style="width: 100%"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="d-flex">
            <div>
              <button type="button" class="btn_red">Онлайн қабул</button>
            </div>
            <div style="margin-left: 15px">
              <button type="button" class="btn_light">Батафсил</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.btn_red {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  padding: 24px 40px;
  background: #ff705d;
  box-shadow: 0px 8px 20px rgba(255, 112, 93, 0.4);
  border-radius: 12px;
  transition: 0.3s all;
  white-space: nowrap;
}

.btn_light {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000;
  box-sizing: border-box;
  padding: 24px 40px;
  background: #fff;
  border-radius: 12px;
  transition: 0.3s all;
  white-space: nowrap;
}

.btn_light:hover,
.btn_light:focus {
  border: 1px solid red;
  color: #ff705d;
  outline: none;
}
</style>