<template>
  <div class="footer">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div>
            <img
              width="80"
              src="../../assets/images/logo/Uzbekistan.png"
              alt="logo"
            />
          </div>
          <div class="navbar-logo-title">
            O'zbekiston Respublikasi <br />Ko'z ilmiy texnologiya markazi
          </div>
        </div>

        <div class="d-flex align-items-center text-center">
          <div class="footer_a_img">
            <a href="">
              <img
                width="25"
                src="../../assets/images/card/telegram.png"
                alt=""
              />
            </a>
          </div>
          <!-- <div class="footer_a_img">
            <a href="">
              <img
                width="25"
                src="../../assets/images/card/youtube (1).png"
                alt=""
              />
            </a>
          </div> -->
          <div class="footer_a_img">
            <a href="">
              <img
                width="25"
                src="../../assets/images/card/instagram.png"
                alt=""
              />
            </a>
          </div>
          <div class="footer_a_img">
            <a href="">
              <img
                width="28"
                src="../../assets/images/card/facebook.png"
                alt=""
              />
            </a>
          </div>
          <div class="footer_a_img">
            <a href="">
              <img
                width="25"
                src="../../assets/images/card/whatsapp.png"
                alt=""
              />
            </a>
          </div>
        </div>

        <div class="footer_right">Copyright © 2024</div>
      </div>
    </div>
  </div>
</template>