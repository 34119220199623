<template>
  <div class="row" style="display: flex; justify-content: center">
    <div class="clinic d-flex">
      <div>
        <div class="clinic_title">Марказ хакида</div>
        <div class="clinic_text" style="margin-bottom: 40px">
          Бизнинг марказ Ўзбекистон Республикаси Президентининг 2003 йил 24
          мартдаги Фармонига кўра ташкил этилган бўлиб, кўзда ўтказиладиган
          барча муолажа ва амалиётларни бажарадиган, ўзида энг тажрибали
          офталмолог ва офталможарроҳларни жамлаб, энг замонавий юқори
          технологияли ускуналар билан жиҳозланган, Ўзбекистон офталмология
          хизматининг етакчи клиникасидир.
        </div>
        <div class="clinic_text">
          <strong>РИКМИАТМ</strong> АЖ Ўзбекистон кўз клиникаларининг фаолиятини
          назорат қилиш билан биргаликда Республиканинг офталмология хизматини
          сифат жиҳатидан янги даражага олиб чиқиш масъулияти юклатилган.
        </div>
        <div>
          <div class="clinic_btn">
            <button type="button" class="btn">Батафсил</button>
          </div>
        </div>
      </div>
      <div>
        <img width="700px" src="../../assets/images/card/clinic2.png"  class="card-image"  alt="" />
      </div>
    </div>
  </div>
</template>
